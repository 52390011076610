@tailwind base;
@tailwind components;
@tailwind utilities; 


@layer utilities {
    .div-style {
      font-size:2em;
    }

    .scrollbar-hide::-webkit-scrollbar {
        display: none;
    }

    div{ font-family: 'Jost', sans-serif;}

    .faded{
      filter: saturate(17%);
    
      /* 35% */
      filter: opacity(45%);
      
    }
    /* .faded:hover{
      filter: saturate(100%);  
    } */

    .player-wrapper {
      position: relative;
    }

    .prevent-select {
      -webkit-user-select: none; /* Safari */
      -ms-user-select: none; /* IE 10 and IE 11 */
      user-select: none; /* Standard syntax */
    }

    .transdiv {
      background-color:rgb(192,38,211, 0.25  )
    }

    .opaquediv {
      background-color:rgb(192,38,211, .78  )
    }

    .uppercase {
      text-transform: uppercase;
    }

    .circleimg {
      border-radius: 50%;
    }

    .bodybackground{
      /* background-color: #09400B;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg %3E%3Cpolygon fill='%23073309' points='1600 160 0 460 0 350 1600 50'/%3E%3Cpolygon fill='%23052607' points='1600 260 0 560 0 450 1600 150'/%3E%3Cpolygon fill='%23041a04' points='1600 360 0 660 0 550 1600 250'/%3E%3Cpolygon fill='%23020d02' points='1600 460 0 760 0 650 1600 350'/%3E%3Cpolygon fill='%23000000' points='1600 800 0 800 0 750 1600 450'/%3E%3C/g%3E%3C/svg%3E");
      background-attachment: fixed;
      background-size: cover; */
    }

    .bgbluegray{
      background-color: #0F172A
      
    }

  }